import React from 'react';

type Level = 1 | 2 | 3 | 4;

const getMaxWidth = (level: Level) => {
  switch (level) {
    case 1:
      return 1190;
    case 2:
      return 1110;
    case 3:
      return 1030;
    case 4:
      return 870;
    default:
      throw new Error('invalid level provided to Section');
  }
};

const Section: React.FC<{
  level: Level;
  className?: string;
  pad?: boolean;
}> = ({ children, level, className, pad }) => (
  <div css={{ display: 'flex', justifyContent: 'center' }}>
    <div
      css={{
        marginLeft: pad ? 0 : 20,
        marginRight: pad ? 0 : 20,
        paddingLeft: pad ? 20 : 0,
        paddingRight: pad ? 20 : 0,
        maxWidth: getMaxWidth(level),
        width: '100%',
      }}
      className={className}
    >
      {children}
    </div>
  </div>
);

export default Section;
