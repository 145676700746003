module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Midtjyllands Kristne Friskole","short_name":"MKF","start_url":"/","background_color":"#fff","theme_color":"#8b415c","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"fb2c2710c2080352300ca85a989e180b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.mkf.dk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["fp-dancer-pro"],"urls":["/fonts/fonts.css"]},"google":{"families":["Zilla+Slab:ital,wght@0,400;0,600;1,400;1,600"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cms.mkf.dk/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layouts/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
