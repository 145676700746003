import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { transparentize } from 'polished';
import Section from './Section';
import Button, { AnchorButton } from './Button';
import Parallax, { Ring, Triangle } from './Parallax';
import { colors, dimensions, layers } from '../styles/variables';
import PhoneIcon from '../icons/phone.svg';
import MailIcon from '../icons/mail.svg';
import { LinkExternalFragment } from '../fragments/LinkExternal';

export const query = graphql`
  query FooterQuery {
    strapi {
      navigation {
        id
        tools {
          ...LinkExternalFragment
        }
        contact_page {
          id
          path
        }
      }
      generalSettings: generalSetting {
        id
        address
        phone
        email
        facebook_url
      }
      footer {
        id
        title
        contact_label
        facebook_label
      }
    }
  }
`;

interface FooterQueryProps {
  strapi: {
    navigation: {
      id: string;
      tools: LinkExternalFragment[];
      contact_page: {
        id: string;
        path: string;
      };
    };
    generalSettings: {
      id: string;
      address: string;
      phone: string;
      email: string;
      facebook_url: string;
    };
    footer: {
      id: string;
      title: string;
      contact_label: string;
      facebook_label: string;
    };
  };
}

const mobileBreakpoint = '@media (max-width: 700px)';

const ParallaxTriangle: React.FC<{ opacity: number }> = ({ opacity }) => (
  <Parallax
    css={{
      left: '61%',
      top: -135,
      [mobileBreakpoint]: {
        left: '25%',
        top: -220,
      },
    }}
    modifier={0.1}
  >
    <Triangle
      css={{
        color: colors.turquoise,
        width: 500,
        transform: 'rotate(19deg)',
        opacity,
      }}
    />
  </Parallax>
);

const Footer: React.FC = () => {
  const { strapi } = useStaticQuery(query) as FooterQueryProps;
  const { navigation, generalSettings, footer } = strapi;

  return (
    <>
      <Section
        level={1}
        css={{
          position: 'relative',
          marginTop: 80,
          zIndex: layers.content,
          [mobileBreakpoint]: { display: 'none' },
        }}
      >
        <Parallax modifier={0.1} css={{ width: '100%', height: '100%' }}>
          <div
            css={{
              left: 15,
              right: 15,
              height: '100%',
              position: 'absolute',
              backgroundColor: transparentize(0.8, colors.lightgray),
              borderRadius: dimensions.borderRadius,
              transform: 'rotate(-1deg)',
            }}
          />
        </Parallax>
        <Section
          level={4}
          css={{
            position: 'relative',
            marginTop: 25,
            marginBottom: 60,
            textAlign: 'center',
            color: colors.blue,
            fontSize: `${dimensions.fontSize.smallest}em`,
          }}
        >
          {navigation.tools.map((link, index) => (
            <span key={link.id}>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                css={{
                  '&:hover, &:focus': {
                    color: colors.purple,
                  },
                }}
              >
                {link.label}
              </a>
              {index !== navigation.tools.length - 1 && (
                <span css={{ color: colors.lightgray, padding: '0 15px' }}>•</span>
              )}
            </span>
          ))}
        </Section>
      </Section>
      <Section
        level={1}
        css={{
          position: 'relative',
          zIndex: layers.content,
          marginTop: -40,
          marginBottom: 110,
          [mobileBreakpoint]: {
            textAlign: 'center',
            marginTop: 40,
          },
        }}
      >
        <ParallaxTriangle opacity={0.1} />
        <Parallax modifier={0.05} css={{ width: '100%', height: '100%' }}>
          <div
            css={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              backgroundColor: colors.blue,
              borderRadius: dimensions.borderRadius,
              transform: 'rotate(0.5deg)',
            }}
          />
        </Parallax>
        <Parallax
          css={{
            left: '48%',
            top: -15,
            [mobileBreakpoint]: {
              display: 'none',
            },
          }}
          modifier={-0.1}
        >
          <Ring css={{ color: colors.orange, width: 143, transform: 'rotate(53deg)' }} />
        </Parallax>
        <ParallaxTriangle opacity={0.05} />
        <Section
          level={4}
          css={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            margin: '55px 0',
            padding: '0 20px',
            color: colors.white,
            [mobileBreakpoint]: {
              display: 'block',
            },
          }}
        >
          <div>
            <div css={{ fontStyle: 'italic', fontSize: 30, marginBottom: '2rem' }}>
              {footer.title}
            </div>
            <p css={{ whiteSpace: 'pre-wrap' }}>{generalSettings.address}</p>
            <p css={{ marginBottom: 0 }}>
              <PhoneIcon css={{ transform: 'translateY(3px)', marginRight: 10, width: 14 }} />
              <a
                href={`tel:${generalSettings.phone}`}
                css={{
                  '&:hover, &:focus': {
                    color: colors.turquoise,
                  },
                }}
              >
                {generalSettings.phone}
              </a>
            </p>
            <p css={{ marginBottom: 0 }}>
              <MailIcon css={{ transform: 'translateY(3px)', marginRight: 10, width: 16 }} />
              <a
                href={`mailto:${generalSettings.email}`}
                css={{
                  '&:hover, &:focus': {
                    color: colors.turquoise,
                  },
                }}
              >
                {generalSettings.email}
              </a>
            </p>
          </div>
          <div
            css={{
              display: 'flex',
              [mobileBreakpoint]: { display: 'block' },
            }}
          >
            <Button
              to={navigation.contact_page.path}
              css={{
                marginRight: 20,
                [mobileBreakpoint]: {
                  display: 'inline-block',
                  marginRight: 5,
                  marginLeft: 5,
                  marginBottom: 10,
                  marginTop: 30,
                },
              }}
            >
              {footer.contact_label}
            </Button>
            <AnchorButton
              href={generalSettings.facebook_url}
              target="_blank"
              rel="noopener noreferrer"
              css={{
                [mobileBreakpoint]: {
                  display: 'inline-block',
                  marginRight: 5,
                  marginLeft: 5,
                },
              }}
            >
              {footer.facebook_label}
            </AnchorButton>
          </div>
        </Section>
      </Section>
    </>
  );
};

export default Footer;
