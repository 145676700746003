import React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { ClassNames } from '@emotion/core';
import { transparentize } from 'polished';
import { dimensions, colors } from '../../styles/variables';
import { MainLink } from '.';

const MenuLink: React.FC<{
  link: MainLink;
  level: number;
  isLast?: boolean;
}> = ({ link, level, isLast }) => {
  const { pathname } = useLocation();
  const isRoot = link.path === '/';
  const showChildren =
    !isRoot &&
    link.children &&
    link.children.length > 0 &&
    (link.path === pathname || pathname.startsWith(`${link.path}/`));

  let fontSize = dimensions.fontSize.smaller;
  if (level === 2) fontSize = dimensions.fontSize.regular;
  if (level === 1) fontSize = dimensions.fontSize.large;

  let margin = 5;
  if (level === 2) margin = 8;
  if (level === 1) margin = 12;

  return (
    <div
      css={{
        margin: `${margin}px 20px`,
        padding: showChildren && level === 1 ? '10px 0 5px' : '0',
        borderTop:
          showChildren && level === 1
            ? `1px solid ${colors.turquoise}`
            : `1px solid ${transparentize(1, colors.turquoise)}`,
        borderBottom:
          !isLast && showChildren && level === 1
            ? `1px solid ${colors.turquoise}`
            : `1px solid ${transparentize(1, colors.turquoise)}`,
      }}
    >
      <ClassNames>
        {({ css }) => (
          <Link
            css={{
              fontSize: `${fontSize}em`,
              textDecoration: level === 1 ? 'none' : 'underline',
              color: colors.white,
              '&:hover, &:focus': {
                textDecoration: level === 1 ? 'none' : 'underline',
              },
            }}
            partiallyActive={!isRoot}
            activeClassName={css`
              font-weight: ${dimensions.fontWeight.bold};
              text-decoration: none !important;
            `}
            to={link.path}
          >
            <div>{link.title}</div>
          </Link>
        )}
      </ClassNames>
      {showChildren &&
        link.children &&
        link.children.map(child => <MenuLink key={child.id} link={child} level={level + 1} />)}
    </div>
  );
};

export default MenuLink;
