import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { Global, css, keyframes } from '@emotion/core';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';
import 'modern-normalize';
import normalize from '../styles/normalize';
import Header from './Header';
import Menu from './Menu';
import Help from './Help';
import Footer from './Footer';
import GoogleTagManager, { trackRouteChange } from './GoogleTagManager';
import { layers } from '../styles/variables';

export const query = graphql`
  query LayoutQuery {
    strapi {
      generalSettings: generalSetting {
        title
        description
      }
    }
  }
`;

interface LayoutQueryProps {
  strapi: {
    generalSettings: {
      title: string;
      description: string;
    };
  };
}

const introAnimation = keyframes`
  0% { opacity: 0; }
  75% { opacity: 0; }
  100% { opacity: 1; }
`;

const Layout: React.FC<{ location: Location }> = ({ children, location }) => {
  const { strapi } = useStaticQuery(query) as LayoutQueryProps;
  const { generalSettings } = strapi;
  const transitionConfig = {
    mass: 1,
    tension: 210,
    friction: 20,
    clamp: true,
  };
  return (
    <TransitionProvider
      location={location}
      enter={{
        opacity: 0,
        transform: 'translate3d(-4px,0,0)',
        config: transitionConfig,
        onRest: trackRouteChange,
      }}
      usual={{
        opacity: 1,
        transform: 'translate3d(0px,0,0)',
      }}
      leave={{
        opacity: 0,
        transform: 'translate3d(4px,0,0)',
        config: transitionConfig,
      }}
    >
      <Global styles={() => css(normalize)} />
      <Helmet
        title={generalSettings.title}
        meta={[{ name: 'description', content: generalSettings.description }]}
      />
      <div css={{ animation: `${introAnimation} 1200ms` }}>
        <Header />
        <Menu />
        <Help />
        <GoogleTagManager />
        <main
          css={{
            position: 'relative',
            paddingTop: 140,
            zIndex: layers.content,
            minHeight: '100vh',
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </main>
        <Footer />
      </div>
    </TransitionProvider>
  );
};

export default Layout;
