import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import useOnWindowScroll from '@rooks/use-on-window-scroll';
import useDidMount from '@rooks/use-did-mount';
import Blob from '../../icons/blob.svg';
import Ring from '../../icons/ring.svg';
import Triangle from '../../icons/triangle.svg';
import TriangleStroke from '../../icons/triangle-stroke.svg';

const Parallax: React.FC<{
  modifier: number;
  className?: string;
}> = ({ children, modifier, className }) => {
  const [parallax, setParallax] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const updateParallax = () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();
      const inView = top < window.innerHeight * 1.5;
      const newParallax = (top - window.innerHeight / 2) * -modifier;
      if (inView) setParallax(newParallax);
    }
  };

  useOnWindowScroll(updateParallax);
  useDidMount(updateParallax);

  return (
    <div
      className={className}
      css={{
        position: 'absolute',
        pointerEvents: 'none',
        overflow: 'visible',
        width: 0,
        height: 0,
      }}
      ref={ref}
    >
      <motion.div
        style={{
          y: parallax,
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export { Blob, Ring, Triangle, TriangleStroke };
export default Parallax;
