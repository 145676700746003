/* eslint-disable max-len */

export const colors = {
  orange: '#f55d07',
  turquoise: '#30d1c7',
  yellow: '#edda2c',
  purple: '#8b415c',
  darkPurple: '#5f3a48',
  blue: '#28456d',
  gray: '#949494',
  lightgray: '#b1b1b0',
  darkgray: '#333333',
  white: '#fff',
  black: '#000',
};

export const fonts = {
  sansSerif:
    'fp-dancer-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: '"Zilla Slab", Georgia, "Times New Roman", Times, serif',
  monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
};

export const dimensions = {
  fontSize: {
    base: '16px',
    regular: 1,
    huge: 1.875,
    xlarge: 1.25,
    large: 1.125,
    small: 0.875,
    smaller: 0.75,
    smallest: 0.625,
  },
  fontWeight: {
    regular: 400,
    bold: 600,
    fat: 900,
  },
  headingSizes: {
    h1: 2.8125,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    compact: 1.3,
    regular: 1.45,
    heading: 1,
  },
  containerPadding: 1.5,
  borderRadius: 6,
};

export const heights = {
  header: 60,
};

export const transitions = {
  default: '400ms cubic-bezier(0.33, 1, 0.68, 1)',
  fast: '250ms cubic-bezier(0.33, 1, 0.68, 1)',
  superFast: '100ms cubic-bezier(0.33, 1, 0.68, 1)',
};

export const layers = {
  mobileMenu: 4,
  help: 3,
  header: 2,
  content: 1,
};
