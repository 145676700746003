import React, { useState } from 'react';
import useKey from '@rooks/use-key';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { transparentize } from 'polished';
import { colors, dimensions, transitions, layers } from '../../styles/variables';
import Section from '../Section';
import CloseIcon from './close-icon.svg';
import PhoneIcon from '../../icons/phone.svg';
import MailIcon from '../../icons/mail.svg';
import PencilIcon from '../../icons/pencil.svg';
import Triangle from '../../icons/triangle.svg';
import Ring from '../../icons/ring.svg';
import { LinkExternalFragment } from '../../fragments/LinkExternal';

export const query = graphql`
  query HelpQuery {
    strapi {
      navigation {
        id
        tools {
          ...LinkExternalFragment
        }
        signup_page {
          id
          path
        }
      }
      generalSettings: generalSetting {
        id
        phone
        email
      }
      helpOverlay {
        id
        title
        body
        signup_label
      }
    }
  }
`;

interface HelpQueryProps {
  strapi: {
    navigation: {
      id: string;
      tools: LinkExternalFragment[];
      signup_page: {
        path: string;
        id: string;
      };
    };
    generalSettings: {
      id: string;
      phone: string;
      email: string;
    };
    helpOverlay: {
      title: string;
      body: string;
      signup_label: string;
    };
  };
}

const Icon: React.FC<{ onToggle: () => void }> = ({ onToggle }) => (
  <div
    css={{
      position: 'fixed',
      zIndex: layers.help,
      right: 28,
      bottom: 28,
      cursor: 'pointer',
      outline: 'none',
      width: 50,
      height: 50,
      backgroundColor: colors.turquoise,
      color: colors.white,
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: `${dimensions.fontSize.huge}rem`,
      fontWeight: dimensions.fontWeight.fat,
      transform: 'rotate(5deg)',
      transition: transitions.fast,
      '&:hover, &:focus': {
        transform: 'rotate(-1deg) scale(1.1)',
      },
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    }}
    role="button"
    onClick={onToggle}
    onKeyPress={onToggle}
    tabIndex={0}
  >
    <div css={{ transform: 'translateY(3px)' }}>?</div>
  </div>
);

const Help: React.FC = () => {
  const { strapi } = useStaticQuery(query) as HelpQueryProps;
  const { navigation, generalSettings, helpOverlay } = strapi;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => setIsOpen(!isOpen);
  useKey(['Escape'], () => isOpen && onToggle());

  const linkStyle = {
    display: 'block',
    textDecoration: 'none',
    transition: transitions.fast,
    borderRadius: dimensions.borderRadius,
    width: '33.3%',
    '&:hover, &:focus': {
      transform: 'translate3d(0, -5px, 0)',
      textDecoration: 'none',
    },
  };

  return (
    <div css={{ '@media (max-width: 620px)': { display: 'none' } }}>
      <Icon onToggle={onToggle} />
      <div
        css={{
          position: 'fixed',
          zIndex: layers.help,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          pointerEvents: isOpen ? 'auto' : 'none',
        }}
      >
        <div
          css={{
            position: 'absolute',
            top: 15,
            right: 15,
            bottom: 15,
            left: 15,
            backgroundColor: colors.blue,
            borderRadius: dimensions.borderRadius,
            opacity: isOpen ? 1 : 0,
            transform: `translate3d(0, ${isOpen ? 0 : 20}px, 0) rotate(${
              isOpen ? 0.5 : 2
            }deg) scale(${isOpen ? 1 : 0.98})`,
            transition: isOpen ? transitions.default : transitions.fast,
          }}
        />
        <div
          css={{
            width: 260,
            position: 'absolute',
            top: -65,
            right: 50,
            color: colors.orange,
            pointerEvents: 'none',
            opacity: isOpen ? 1 : 0,
            transform: `translate3d(0, ${isOpen ? 0 : -15}px, 0) rotate(-81deg)`,
            transition: isOpen ? `${transitions.default} 300ms` : transitions.fast,
          }}
        >
          <Ring css={{ path: { strokeWidth: 1.3 } }} />
        </div>
        <div
          css={{
            opacity: isOpen ? 1 : 0,
            transition: `${transitions.fast} ${isOpen ? '200ms' : '0ms'}`,
            transform: `translate3d(0, ${isOpen ? 0 : 5}px, 0)`,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 60,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.white,
            textAlign: 'center',
          }}
        >
          <div
            css={{
              fontSize: `${dimensions.headingSizes.h1}rem`,
              fontWeight: dimensions.fontWeight.fat,
              transform: 'rotate(-1deg)',
              marginBottom: 20,
            }}
          >
            {helpOverlay.title}
          </div>
          <p
            css={{
              fontSize: `${1.25}rem`,
              maxWidth: 520,
            }}
          >
            {helpOverlay.body}
          </p>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: 1000,
              padding: 20,
              marginTop: 32,
              fontStyle: 'italic',
              fontSize: `${dimensions.fontSize.huge}rem`,
            }}
          >
            <a href={`tel:${generalSettings.phone}`} css={linkStyle}>
              <div css={{ height: 50, display: 'flex', justifyContent: 'center' }}>
                <PhoneIcon css={{ width: 40 }} />
              </div>
              {generalSettings.phone}
            </a>
            <a href={`mailto:${generalSettings.email}`} css={linkStyle}>
              <div css={{ height: 50, display: 'flex', justifyContent: 'center' }}>
                <MailIcon css={{ width: 44 }} />
              </div>
              {generalSettings.email}
            </a>
            <Link to={navigation.signup_page.path} css={linkStyle} onClick={onToggle}>
              <div css={{ height: 50, display: 'flex', justifyContent: 'center' }}>
                <PencilIcon css={{ width: 48 }} />
              </div>
              {helpOverlay.signup_label}
            </Link>
          </div>
        </div>
        <div
          css={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <Section level={3} css={{ position: 'relative' }}>
            <div
              css={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                left: 0,
                height: '100%',
                width: '100%',
                backgroundColor: transparentize(0.8, colors.gray),
                borderRadius: dimensions.borderRadius,
                opacity: isOpen ? 1 : 0,
                transform: `translate3d(0, ${isOpen ? 0 : 10}px, 0) rotate(-1deg)`,
                transition: `${transitions.fast} ${isOpen ? '200ms' : '0ms'}`,
              }}
            />
            <div
              css={{
                width: 160,
                position: 'absolute',
                top: -50,
                left: -100,
                color: colors.turquoise,
                pointerEvents: 'none',
                opacity: isOpen ? 1 : 0,
                transform: `translate3d(0, ${isOpen ? 0 : 30}px, 0) rotate(-69deg)`,
                transition: isOpen ? `${transitions.default} 200ms` : transitions.fast,
              }}
            >
              <Triangle />
            </div>
            <div
              css={{
                position: 'relative',
                opacity: isOpen ? 1 : 0,
                transition: `${transitions.fast} ${isOpen ? '300ms' : '0ms'}`,
                color: colors.white,
                textAlign: 'center',
                fontSize: `${dimensions.fontSize.smallest}em`,
                paddingTop: 30,
                paddingBottom: 45,
              }}
            >
              {navigation.tools.map((link, index) => (
                <span key={link.id}>
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{
                      '&:hover, &:focus': {
                        color: colors.turquoise,
                      },
                    }}
                  >
                    {link.label}
                  </a>
                  {index !== navigation.tools.length - 1 && (
                    <span css={{ color: colors.lightgray, padding: '0 15px' }}>•</span>
                  )}
                </span>
              ))}
            </div>
          </Section>
        </div>
        <div
          css={{
            position: 'absolute',
            top: 27,
            left: 35,
            cursor: 'pointer',
            outline: 'none',
            padding: 10,
            opacity: isOpen ? 1 : 0,
            transition: `${transitions.fast} ${isOpen ? '200ms' : '0ms'}`,
          }}
          role="button"
          onClick={onToggle}
          onKeyPress={onToggle}
          tabIndex={0}
        >
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default Help;
