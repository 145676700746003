import React from 'react';
import useCookie from 'react-use-cookie';
import { colors, dimensions, fonts, transitions } from '../styles/variables';
import { ClickButton } from './Button';

const id = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID;
const cookieName = 'tracking-consent';
const routeChangeEvent = 'gatsby-route-change';
const elementId = 'google-tag-manager';

let trackingEnabled = false;
const injectTracker = () => {
  if (id && !trackingEnabled) {
    trackingEnabled = true;
    (document.getElementById(elementId) as Element).innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${id}');`;
  }
};

export const trackRouteChange = () => {
  if (trackingEnabled && window.dataLayer) {
    window.dataLayer.push({ event: routeChangeEvent, pathname: window.location.pathname });
  }
};

const GoogleTagManager: React.FC = () => {
  const [consent, setConsent] = useCookie(cookieName, 'unset');
  const showModal = consent === 'unset';

  if (consent === '1') injectTracker();

  return !showModal ? null : (
    <div
      css={{
        position: 'fixed',
        zIndex: 999,
        bottom: 10,
        left: 10,
        marginRight: 10,
        transition: transitions.fast,
      }}
    >
      <div
        css={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: colors.blue,
          borderRadius: dimensions.borderRadius,
          transform: 'rotate(-0.5deg)',
        }}
      />
      <div
        css={{
          position: 'relative',
          color: colors.white,
          padding: '22px 30px',
          fontFamily: fonts.serif,
          display: 'flex',
          alignItems: 'center',
          fontSize: `${dimensions.fontSize.small}rem`,
          [`@media (max-width: 600px)`]: {
            display: 'block',
            padding: 22,
          },
        }}
      >
        <div
          css={{
            maxWidth: 360,
            marginRight: 20,
            [`@media (max-width: 600px)`]: {
              marginRight: 0,
              marginBottom: 12,
              textAlign: 'center',
            },
          }}
        >
          Vi bruger cookies til at forbedre din browseroplevelse på vores website, for at analysere
          vores trafik og forstå, hvor vores besøgende kommer fra. Ved at bruge websitet accepterer
          du vores brug af cookies.
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ClickButton
            type="button"
            css={{
              backgroundColor: colors.blue,
              color: colors.white,
              fontSize: `${dimensions.fontSize.regular}rem`,
            }}
            onClick={() => {
              setConsent('1');
              setTimeout(trackRouteChange, 100);
            }}
          >
            Det er fint med mig!
          </ClickButton>
          <div
            css={{
              marginTop: 10,
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover, &:focus': {
                textDecoration: 'none',
              },
            }}
            onClick={() => setConsent('0')}
            onKeyPress={() => setConsent('0')}
            role="button"
            tabIndex={0}
          >
            Nej, jeg ønsker ikke cookies
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleTagManager;
