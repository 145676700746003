import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { transparentize } from 'polished';
import { colors, transitions, layers } from '../../styles/variables';
import Button from '../Button';
import Icon from './Icon';
import MenuLink from './MenuLink';
import Tools from './Tools';
import { LinkExternalFragment } from '../../fragments/LinkExternal';

export const query = graphql`
  query MenuQuery {
    strapi {
      navigation {
        id
        main {
          id
          page {
            id
            title
            path
            children {
              id
              title
              path
              children {
                id
                title
                path
              }
            }
          }
        }
        tools {
          ...LinkExternalFragment
        }
        contact_page {
          id
          path
        }
      }
      mobileMenu {
        contact_label
      }
    }
  }
`;

export type MainLink = {
  id: string;
  title: string;
  path: string;
  children?: MainLink[];
};

interface MenuQueryProps {
  strapi: {
    navigation: {
      id: string;
      main: {
        page: {
          id: string;
          title: string;
          path: string;
          children: MainLink[];
        };
      }[];
      tools: LinkExternalFragment[];
      contact_page: {
        id: string;
        path: string;
      };
    };
    mobileMenu: {
      contact_label: string;
    };
  };
}

const Menu: React.FC = () => {
  const { strapi } = useStaticQuery(query) as MenuQueryProps;
  const { navigation, mobileMenu } = strapi;
  const { pathname } = useLocation();
  const [openedAtPathname, setOpenedAtPathname] = useState<string | undefined>();
  const isOpen = pathname === openedAtPathname;
  const onToggle = () => setOpenedAtPathname(isOpen ? undefined : pathname);

  return (
    <div css={{ '@media (min-width: 620px)': { display: 'none' } }}>
      <div
        css={{
          position: 'fixed',
          zIndex: layers.mobileMenu,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: colors.blue,
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? 'auto' : 'none',
          transition: transitions.default,
        }}
      >
        <div
          css={{
            opacity: isOpen ? 1 : 0,
            transform: `translate3d(${isOpen ? 0 : -10}px, 0, 0)`,
            transition: `${transitions.default} 200ms`,
            height: '100%',
            overflow: 'scroll',
            paddingTop: 88,
            paddingBottom: 100,
          }}
        >
          <MenuLink level={1} link={{ id: '', title: 'Forside', path: '/' }} />
          {navigation.main.map((link, index) => (
            <MenuLink
              key={link.page.id}
              link={link.page}
              level={1}
              isLast={index === navigation.main.length - 1}
            />
          ))}
          <Tools tools={navigation.tools} />
        </div>
        <div
          css={{
            position: 'absolute',
            width: '100%',
            top: 0,
            height: 90,
            background: `linear-gradient(180deg, ${colors.blue} 63%, ${transparentize(
              1,
              colors.blue,
            )} 100%)`,
          }}
        />
        <div
          css={{
            position: 'absolute',
            width: '100%',
            bottom: 0,
            height: 90,
            padding: 20,
            color: colors.white,
            background: `linear-gradient(0deg, ${colors.blue} 63%, ${transparentize(
              1,
              colors.blue,
            )} 100%)`,
          }}
        >
          <Button to={navigation.contact_page.path}>{mobileMenu.contact_label}</Button>
        </div>
      </div>
      <Icon onToggle={onToggle} isOpen={isOpen} />
    </div>
  );
};

export default Menu;
