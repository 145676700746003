import React, { useState } from 'react';
import { colors, transitions, dimensions } from '../../styles/variables';
import { LinkExternalFragment } from '../../fragments/LinkExternal';
import Arrow from './arrow.svg';

const Tools: React.FC<{ tools: LinkExternalFragment[] }> = ({ tools }) => {
  const [showTools, setShowTools] = useState(false);

  return (
    <div
      css={{
        margin: 20,
        paddingTop: 20,
        borderTop: `1px solid ${colors.yellow}`,
        color: colors.white,
        fontSize: `${dimensions.fontSize.small}em`,
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setShowTools(!showTools)}
        onKeyPress={() => setShowTools(!showTools)}
        role="button"
        tabIndex={0}
      >
        <span>Værktøjer</span>
        <Arrow
          css={{ transform: showTools ? 'scaleY(-1)' : 'scaleY(1)', transition: transitions.fast }}
        />
      </div>
      <div
        css={{
          maxHeight: showTools ? tools.length * 28 : 0,
          opacity: showTools ? 1 : 0,
          overflow: 'hidden',
          transition: showTools
            ? `max-height ${transitions.fast}, opacity ${transitions.fast} 200ms`
            : `opacity ${transitions.fast}, max-height ${transitions.fast} 200ms`,
        }}
      >
        {tools.map(link => (
          <a
            css={{
              display: 'block',
              marginTop: 8,
              marginLeft: 20,
              textDecoration: 'underline',
            }}
            key={link.id}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Tools;
