import { dimensions, fonts, colors, breakpoints } from './variables';

export default `
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    font-size: ${dimensions.fontSize.base} !important;
    line-height: ${dimensions.lineHeight.regular} !important;
    overflow-x: scroll;
  }

  body {
    overflow: hidden;
    margin: 0;
    height: 100%;
    font-family: ${fonts.sansSerif};
    color: ${colors.darkgray};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }


  a {
    color: inherit;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
    position: relative;
  }

  figure {
    margin: 1rem 0;
  }

  figcaption {
    color: ${colors.gray};
    font-family: ${fonts.serif};
    font-size: ${dimensions.fontSize.small}rem;
    font-style: italic;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    font-size: 85%;
    border-collapse: collapse;
  }

  td,
  th {
    padding: .25rem .5rem;
  }

  th {
    text-align: left;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.414rem;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin-top: 0;
    font-size: ${dimensions.headingSizes.h1}rem;
    font-weight: ${dimensions.fontWeight.fat};

    @media (max-width: ${breakpoints.md}px) {
      font-size: ${dimensions.fontSize.huge}rem;
    }
  }

  h2 {
    font-size: ${dimensions.headingSizes.h2}rem;
  }

  h3 {
    font-size: ${dimensions.headingSizes.h3}rem;
  }

  h4, h5, h6 {
    font-size: ${dimensions.headingSizes.h4}rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: ${fonts.serif};
  }

  li {
    font-family: ${fonts.serif};
  }

  ul, ol {
    list-style: none;

    li::before {
      content: "•";
      color: ${colors.purple};
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
    }
  }

  ol {
    counter-reset: li;

    li::before {
      content: counter(li) ".";
      counter-increment: li;
    }
  }


  strong {
    color: ${colors.black};
  }

  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: .5rem;
  }

  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.gray};
  }

  blockquote {
    margin: .8rem 0;
    padding: .5rem 1rem;
    border-left: .25rem solid ${colors.gray};
    color: ${colors.gray.calm};

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${breakpoints.md / dimensions.fontSize.regular}em) {
      padding-right: 5rem;
      padding-left: 1.25rem;
    }
  }
`;
