import React, { useEffect, useRef } from 'react';
import usePrevious from '@rooks/use-previous';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ClassNames } from '@emotion/core';
import useScrollPosition from '@react-hook/window-scroll';
import { useLocation } from '@reach/router';
import Logo from './logo.svg';
import LogoText from './logo_text.svg';
import { dimensions, colors, layers } from '../../styles/variables';
import { LinkInternalFragment } from '../../fragments/LinkInternal';

export const query = graphql`
  query HeaderQuery {
    strapi {
      navigation {
        id
        main {
          ...LinkInternalFragment
        }
      }
    }
  }
`;

interface HeaderQueryProps {
  strapi: {
    navigation: {
      id: string;
      main: LinkInternalFragment[];
    };
  };
}

const transition = '400ms cubic-bezier(0.33, 1, 0.68, 1);';
const mobileBreakpoint = '@media (max-width: 620px)';
const condenseBreakpoint = '@media (max-width: 820px)';

const partiallyActive = (location: string, pathname: string) =>
  location === pathname || location.startsWith(`${pathname}/`);

const Stroke: React.FC<{ path: string; strokeLength: number }> = ({ path, strokeLength }) => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);
  const pathRef = useRef<SVGPathElement>(null);

  // Warning! Overly complex implementation for something seemingly trivial
  // incoming: Set dashoffset of path to 57 for it to animate to the opposite
  // side of the enter animation. Then, reset the dashoffset without transition
  // and finally remove the 0ms transition
  useEffect(() => {
    if (
      previousPathname &&
      partiallyActive(previousPathname, path) &&
      !partiallyActive(pathname, path) &&
      pathRef.current
    ) {
      pathRef.current.style.strokeDashoffset = `${strokeLength}px`;
      setTimeout(() => {
        if (pathRef.current) {
          pathRef.current.style.strokeDashoffset = '';
          pathRef.current.style.transition = '0ms';
          setTimeout(() => {
            if (pathRef.current) {
              pathRef.current.style.transition = '';
            }
          }, 100);
        }
      }, 400);
    }
  }, [pathname]);

  return (
    <svg
      css={{
        display: 'block',
        margin: '10px auto 0',
        width: strokeLength,
      }}
      viewBox="0 0 57 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3C19.3044 1.14561 37.5755 0.733357 56 1.14667"
        css={{
          stroke: colors.turquoise,
          strokeWidth: 2,
          strokeDasharray: strokeLength,
          strokeDashoffset: strokeLength * 3,
          transition: '200ms',
        }}
        ref={pathRef}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const MainNavLink: React.FC<{ link: LinkInternalFragment }> = ({ link }) => {
  const strokeLength = 57;

  return (
    <ClassNames>
      {({ css }) => (
        <Link
          css={{
            fontSize: `${dimensions.fontSize.large}em`,
            fontWeight: dimensions.fontWeight.bold,
            margin: '0 25px',
            whiteSpace: 'nowrap',
            color: colors.blue,
            transition,
            textDecoration: 'none',
            '&:hover, &:focus': {
              textDecoration: 'none',
              color: colors.black,
              path: {
                strokeDashoffset: strokeLength * 2,
              },
            },
            [condenseBreakpoint]: {
              fontSize: `${dimensions.fontSize.regular}em`,
              margin: '0 10px',
            },
            [mobileBreakpoint]: {
              fontSize: `${dimensions.fontSize.smaller}em`,
              margin: '0 6px',
            },
          }}
          partiallyActive
          activeClassName={css`
            color: ${colors.black};
            path {
              stroke-dashoffset: ${strokeLength * 2};
            }
          `}
          to={link.page.path}
        >
          {link.page.title}
          <Stroke path={link.page.path} strokeLength={strokeLength} />
        </Link>
      )}
    </ClassNames>
  );
};

const LogoLink: React.FC<{ isScrolled: boolean }> = ({ isScrolled }) => (
  <div
    css={{
      width: 0,
      height: 0,
      margin: '-35px 110px',
      [condenseBreakpoint]: {
        margin: '-35px 70px',
      },
      [mobileBreakpoint]: {
        margin: '-80px 0px',
      },
    }}
  >
    <div
      css={{
        width: 80,
        marginLeft: -40,
        overflow: 'visible',
        transform: isScrolled ? 'scale(0.625) translateY(10px)' : 'scale(1) translateY(0px)',
        transition,
        [mobileBreakpoint]: {
          width: 48,
          transform: isScrolled ? 'scale(0.625) translateY(44px)' : 'scale(1) translateY(0px)',
        },
      }}
    >
      <Link to="/">
        <Logo css={{ width: '100%' }} />
        <LogoText
          css={{
            marginLeft: '15%',
            width: '80%',
            transition,
            opacity: isScrolled ? 0 : 1,
          }}
        />
      </Link>
    </div>
  </div>
);

const Header: React.FC = () => {
  const mainNav = (useStaticQuery(query) as HeaderQueryProps).strapi.navigation.main;
  const scrollY = useScrollPosition(60);

  const isScrolled = scrollY > 0;

  return (
    <header
      css={{
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: layers.header,
        display: 'flex',
        justifyContent: 'center',
        transition,
        [mobileBreakpoint]: {
          height: 90,
          marginBottom: 22,
        },
      }}
    >
      <div
        css={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          marginTop: -2,
          backgroundColor: 'rgba(255,255,255,1)',
          boxShadow: '0px 2px 7px rgba(40, 69, 109, 0.1)',
          opacity: isScrolled ? 1 : 0,
          transform: isScrolled
            ? 'translateY(0) rotate(-0.2deg)'
            : 'translateY(-5px) rotate(-0.2deg)',
          transition,
        }}
      />
      <nav
        css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 40,
          marginBottom: 10,
          transform: isScrolled ? 'translateY(-8px)' : 'translateY(12px)',
          transition,
          [mobileBreakpoint]: {
            marginTop: 84,
            transform: isScrolled ? 'translateY(-32px)' : 'translateY(12px)',
          },
        }}
      >
        <MainNavLink link={mainNav[0]} />
        <MainNavLink link={mainNav[1]} />
        <LogoLink isScrolled={isScrolled} />
        <MainNavLink link={mainNav[2]} />
        <MainNavLink link={mainNav[3]} />
      </nav>
    </header>
  );
};

export default Header;
