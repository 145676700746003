import React from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { colors, transitions, layers } from '../../styles/variables';

const Bar: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <div
    css={{
      transition: transitions.default,
      width: 50,
      height: 3,
      marginBottom: 3,
      backgroundColor: isOpen ? colors.white : colors.blue,
    }}
  />
);

const Icon: React.FC<{ onToggle: () => void; isOpen: boolean }> = ({ onToggle, isOpen }) => {
  const scrollY = useScrollPosition(60);
  const isScrolled = scrollY > 0;

  return (
    <div
      css={{
        position: 'fixed',
        zIndex: layers.mobileMenu,
        top: isScrolled && !isOpen ? 18 : 38,
        left: 20,
        cursor: 'pointer',
        transition: transitions.default,
        outline: 'none',
      }}
      role="button"
      onClick={onToggle}
      onKeyPress={onToggle}
      tabIndex={0}
    >
      <div css={{ overflow: 'hidden', width: 26, height: 18 }}>
        <div
          css={{
            transition: transitions.default,
            transform: `translate3d(${isOpen ? 6 : -18}px,-22px,0px) rotate(45deg)`,
            width: 40,
            height: 40,
            overflow: 'hidden',
          }}
        >
          <div css={{ transform: 'rotate(-45deg) translate3d(-15px, 20px, 0px)' }}>
            <Bar isOpen={isOpen} />
            <Bar isOpen={isOpen} />
            <Bar isOpen={isOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Icon;
