import styled, { CSSObject } from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, fonts, dimensions, transitions, breakpoints } from '../styles/variables';

const style: CSSObject = {
  display: 'block',
  padding: '12px 24px',
  border: `2px solid ${colors.turquoise}`,
  borderRadius: 9999,
  textDecoration: 'none',
  textAlign: 'center',
  fontFamily: fonts.serif,
  fontWeight: dimensions.fontWeight.bold,
  cursor: 'pointer',
  transition: transitions.fast,
  whiteSpace: 'nowrap',
  '&:hover, &:focus': {
    textDecoration: 'none',
    backgroundColor: colors.turquoise,
  },
  [`@media (max-width: ${breakpoints.md}px)`]: {
    fontSize: `${dimensions.fontSize.small}rem`,
  },
};

const Button = styled(Link)(style);
export const AnchorButton = styled.a(style);
export const ClickButton = styled.button(style);

export default Button;
